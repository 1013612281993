import React from 'react';
import './Footer.css'
import mogeeLogo from '../../../../Assets/Img/mogee-logo.png';
import linkdinlogo from '../../../../Assets/Img/socials/linkdin-white-icon.png';
import instagramlogo from '../../../../Assets/Img/socials/insta-white-icon.png';
import { Link } from 'react-router-dom';

const Footer: React.FC = () => {
    return (
        <footer className="main-site-footer">
            <ul className="main-site-footer-listing-ul">
                <li className="main-site-footer-logo-li">
                    <ul className="main-site-footer-logo-listing-ul">
                        <li className="main-site-footer-inner-logo-li">
                            <a href="index.php" className="main-site-footer-logo-a">
                                <img src={mogeeLogo} alt='MOGEE' className="main-site-footer-logo" />
                            </a>
                        </li>
                        <li className="main-site-footer-logo-info-li">
                            <p className="main-site-footer-info-txt-p">info@mogee.nl</p>
                            <p className="main-site-footer-info-txt-p">+31 6 12345678</p>
                            <p className="main-site-footer-info-txt-p">Columbusplein 54-HS<br />
                                1057 VC Amsterdam<br />
                                Noord-Holland Nederland</p>
                        </li>
                        <li className="main-site-footer-logo-info-li">
                            <div className="main-site-social-item-div">
                                <a href="https://www.linkedin.com/company/mogeeproffesionals/" target="_blank" rel="noopener"
                                    className="main-site-footer-social-item-a">
                                    <img src={linkdinlogo} alt='LinkdIn' className="main-site-footer-social-item-img" />
                                </a>
                            </div>
                            <div className="main-site-social-item-div">
                                <a href="https://www.instagram.com/mogee.nl/" target="_blank" rel="noopener"
                                    className="main-site-footer-social-item-a">
                                    <img src={instagramlogo} alt='InstaGram' className="main-site-footer-social-item-img" />
                                </a>
                            </div>
                        </li>
                    </ul>
                </li>
                <li className="main-site-footer-links-li">
                    <ul className="main-site-footer-inner-links-listing-ul">
                        <li className="main-site-footer-inner-links-block-li">
                            <h4 className="main-site-footer-title-h4">Website</h4>
                            <ul className="main-site-footer-links-listing-ul">
                                <li className="main-site-footer-link-item-li">
                                    <p className="main-site-footer-link-txt-p"><Link to="/" className="main-site-footer-link-href-a">Home</Link></p>
                                </li>
                                <li className="main-site-footer-link-item-li">
                                    <p className="main-site-footer-link-txt-p"><Link to="/#portfolio" className="main-site-footer-link-href-a">Portfolio</Link></p>
                                </li>
                                <li className="main-site-footer-link-item-li">
                                    <p className="main-site-footer-link-txt-p"><Link to="/over-ons" className="main-site-footer-link-href-a">Over ons</Link></p>
                                </li>
                                <li className="main-site-footer-link-item-li">
                                    <p className="main-site-footer-link-txt-p"><Link to="/contact" className="main-site-footer-link-href-a">Contact</Link></p>
                                </li>
                            </ul>
                        </li>
                        {/* <li className="main-site-footer-inner-links-block-li">
                            <h4 className="main-site-footer-title-h4">Handige links</h4>
                            <ul className="main-site-footer-links-listing-ul">
                                <li className="main-site-footer-link-item-li">
                                    <p className="main-site-footer-link-txt-p"><a href="algemene-voorwaarden.php" className="main-site-footer-link-href-a">Algemene Voorwaarden</a></p>
                                </li>
                                <li className="main-site-footer-link-item-li">
                                    <p className="main-site-footer-link-txt-p"><a href="privacy-overeenkomst.php" className="main-site-footer-link-href-a">Privacy Overeenkomst</a></p>
                                </li>
                            </ul>
                        </li> */}
                    </ul>
                </li>
            </ul >
            <ul className="main-site-footer-copyright-ul">
                <p className="main-site-footer-copyright-txt-p">Alle rechten voorbehouden ©
                    Mogee
                </p>
            </ul>
        </footer >
    );
};

export default Footer;