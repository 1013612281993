import React, { useEffect, useState } from 'react';
import './NavBar.css';
import logo from '../../../../Assets/Img/mogee-logo.png';
import blacklogo from '../../../../Assets/Img/mogee-logo-black.png';
import { Link } from 'react-router-dom';
import HamburgerMenu from './HamburgerMenu/HamburgerMenu';

interface NavBarProps {
    darkmode?: boolean;
}

const NavBar: React.FC<NavBarProps> = ({ darkmode = false }) => {
    const [isMobile, setIsMobile] = useState(window.innerWidth <= 768);

    useEffect(() => {
        const handleResize = () => {
            setIsMobile(window.innerWidth <= 768);
        };

        window.addEventListener('resize', handleResize);
        return () => {
            window.removeEventListener('resize', handleResize);
        };
    }, []);

    return (
        <nav className={`main-menu-nav ${darkmode ? 'dark' : ''}`}>
            <ul className="main-menu-listing">
                <li className="main-menu-logo-li">
                    <Link to="/" className='main-menu-link'>
                        <img src={darkmode ? blacklogo : logo} alt='MOGEE' className="main-menu-logo-link-img normal" />
                    </Link>
                </li>
                <li className="main-menu-links-li">
                    <div className="main-menu-links-list">
                        <ul className="main-menu-links-submenu-trigger-ul">
                            <li className="main-menu-link-li">
                                <Link to="/#portfolio" className='main-menu-link'>
                                    <p className="main-menu-link-txt-p">Portfolio</p>
                                </Link>
                            </li>
                        </ul>
                        <div className="main-menu-drop-div main-menu-services-drop">
                            <div className="main-menu-drop-submenu-content-div">
                                <div className="main-menu-inner-submenu-div">
                                    <h2 className="main-menu-submenu-title-h2">Projecten</h2>
                                    <ul className="main-menu-submenu-listing-ul">
                                        <li className="main-menu-submenu-item-li">
                                            <p className="main-menu-big-submenu-item-p"><a href="project.php"
                                                className="main-menu-submenu-item-a">Moos</a></p>
                                        </li>
                                        <li className="main-menu-submenu-item-li">
                                            <p className="main-menu-big-submenu-item-p"><a href="project.php"
                                                className="main-menu-submenu-item-a">10-Things</a></p>
                                        </li>
                                        <li className="main-menu-submenu-item-li">
                                            <p className="main-menu-big-submenu-item-p"><a href="project.php"
                                                className="main-menu-submenu-item-a">Rittenplanner</a></p>
                                        </li>
                                        <li className="main-menu-submenu-item-li">
                                            <p className="main-menu-big-submenu-item-p"><a href="project.php"
                                                className="main-menu-submenu-item-a">Excel AI</a></p>
                                        </li>
                                    </ul>
                                </div>

                                <div className="main-menu-inner-submenu-div">
                                    <h2 className="main-menu-submenu-title-h2">Snelle Links</h2>
                                    <ul className="main-menu-submenu-listing-ul">
                                        <li className="main-menu-submenu-item-li">
                                            <p className="main-menu-submenu-item-p"><a href="aanhuur-software.php"
                                                className="main-menu-submenu-item-a">Vraag een prijsopgave aan</a></p>
                                        </li>
                                        <li className="main-menu-submenu-item-li">
                                            <p className="main-menu-submenu-item-p"><a href="aanhuur-software.php"
                                                className="main-menu-submenu-item-a">Bekijk onze projecten</a></p>
                                        </li>
                                        <li className="main-menu-submenu-item-li">
                                            <p className="main-menu-submenu-item-p"><a href="aanhuur-software.php"
                                                className="main-menu-submenu-item-a">Bekijk ons team</a></p>
                                        </li>
                                    </ul>
                                </div>
                            </div>
                        </div>
                    </div>

                    <div className="main-menu-links-list">
                        <ul className="main-menu-links-submenu-trigger-ul">
                            <li className="main-menu-link-li">
                                <Link to="/#services" className='main-menu-link'>
                                    <p className="main-menu-link-txt-p">Services</p>
                                </Link>
                            </li>
                        </ul>
                        <div className="main-menu-drop-div main-menu-producten-drop">
                            <div className="main-menu-drop-submenu-content-div">
                                <div className="main-menu-inner-submenu-div">
                                    <h2 className="main-menu-submenu-title-h2">Services</h2>
                                    <ul className="main-menu-submenu-listing-ul">
                                        <li className="main-menu-submenu-item-li">
                                            <p className="main-menu-big-submenu-item-p"><a href="software-ontwikkeling.php"
                                                className="main-menu-submenu-item-a">Software Ontwikkeling</a></p>
                                        </li>
                                        <li className="main-menu-submenu-item-li">
                                            <p className="main-menu-big-submenu-item-p"><a href="aanhuur-software.php"
                                                className="main-menu-submenu-item-a">Software Doorontwikkeling</a></p>
                                        </li>
                                        <li className="main-menu-submenu-item-li">
                                            <p className="main-menu-big-submenu-item-p"><a href="aanhuur-software.php"
                                                className="main-menu-submenu-item-a">Hosting</a></p>
                                        </li>
                                    </ul>
                                </div>

                                <div className="main-menu-inner-submenu-div">
                                    <h2 className="main-menu-submenu-title-h2">Snelle Links</h2>
                                    <ul className="main-menu-submenu-listing-ul">
                                        <li className="main-menu-submenu-item-li">
                                            <p className="main-menu-submenu-item-p"><a href="aanhuur-software.php"
                                                className="main-menu-submenu-item-a">Vraag een prijsopgave aan</a></p>
                                        </li>
                                        <li className="main-menu-submenu-item-li">
                                            <p className="main-menu-submenu-item-p"><a href="aanhuur-software.php"
                                                className="main-menu-submenu-item-a">Bekijk onze projecten</a></p>
                                        </li>
                                        <li className="main-menu-submenu-item-li">
                                            <p className="main-menu-submenu-item-p"><a href="aanhuur-software.php"
                                                className="main-menu-submenu-item-a">Bekijk ons team</a></p>
                                        </li>
                                    </ul>
                                </div>
                            </div>
                        </div>
                    </div>

                    <div className="main-menu-links-list">
                        <ul className="main-menu-links-submenu-trigger-ul">
                            <li className="main-menu-link-li">
                                <Link to="/over-ons" className='main-menu-link'>
                                    <p className="main-menu-link-txt-p">Over ons</p>
                                </Link>
                            </li>
                        </ul>
                        <div className="main-menu-drop-div main-menu-projecten-drop">
                            <div className="main-menu-drop-submenu-content-div">
                                <div className="main-menu-inner-submenu-div">
                                    <h2 className="main-menu-submenu-title-h2">Over ons</h2>
                                    <ul className="main-menu-submenu-listing-ul">
                                        <li className="main-menu-submenu-item-li">
                                            <p className="main-menu-big-submenu-item-p"><a href="ons-team.php"
                                                className="main-menu-submenu-item-a">Ons Team</a></p>
                                        </li>
                                        <li className="main-menu-submenu-item-li">
                                            <p className="main-menu-big-submenu-item-p"><a href="onze-werkwijze.php"
                                                className="main-menu-submenu-item-a">Onze werkwijze</a></p>
                                        </li>
                                        <li className="main-menu-submenu-item-li">
                                            <p className="main-menu-big-submenu-item-p"><a href="historie.php"
                                                className="main-menu-submenu-item-a">Historie</a></p>
                                        </li>
                                        <li className="main-menu-submenu-item-li">
                                            <p className="main-menu-big-submenu-item-p"><a href="faq.php"
                                                className="main-menu-submenu-item-a">FAQ</a></p>
                                        </li>
                                    </ul>
                                </div>

                                <div className="main-menu-inner-submenu-div">
                                    <h2 className="main-menu-submenu-title-h2">Snelle Links</h2>
                                    <ul className="main-menu-submenu-listing-ul">
                                        <li className="main-menu-submenu-item-li">
                                            <p className="main-menu-submenu-item-p"><a href="aanhuur-software.php"
                                                className="main-menu-submenu-item-a">Vraag een prijsopgave aan</a></p>
                                        </li>
                                        <li className="main-menu-submenu-item-li">
                                            <p className="main-menu-submenu-item-p"><a href="aanhuur-software.php"
                                                className="main-menu-submenu-item-a">Bekijk onze projecten</a></p>
                                        </li>
                                        <li className="main-menu-submenu-item-li">
                                            <p className="main-menu-submenu-item-p"><a href="aanhuur-software.php"
                                                className="main-menu-submenu-item-a">Bekijk ons team</a></p>
                                        </li>
                                    </ul>
                                </div>
                            </div>
                        </div>
                    </div>

                    <div className="main-menu-links-list">
                        <ul className="main-menu-links-submenu-trigger-ul">
                            <li className="main-menu-link-li">
                                <Link to="/contact" className='main-menu-link'>
                                    <p className="main-menu-link-txt-p">Contact</p>
                                </Link>
                            </li>
                        </ul>
                        <div className="main-menu-drop-div main-menu-projecten-drop">
                            <div className="main-menu-drop-submenu-content-div">
                                <div className="main-menu-inner-submenu-div">
                                    <h2 className="main-menu-submenu-title-h2">Neem contact op</h2>
                                    <ul className="main-menu-submenu-listing-ul">
                                        <li className="main-menu-submenu-item-li">
                                            <p className="main-menu-big-submenu-item-p"><a href="contact.php"
                                                className="main-menu-submenu-item-a">Voor consumenten</a></p>
                                        </li>
                                        <li className="main-menu-submenu-item-li">
                                            <p className="main-menu-big-submenu-item-p"><a href="klanten-contact.php"
                                                className="main-menu-submenu-item-a">Voor klanten</a></p>
                                        </li>
                                    </ul>
                                </div>

                                <div className="main-menu-inner-submenu-div">
                                    <h2 className="main-menu-submenu-title-h2">Snelle Links</h2>
                                    <ul className="main-menu-submenu-listing-ul">
                                        <li className="main-menu-submenu-item-li">
                                            <p className="main-menu-submenu-item-p"><a href="aanhuur-software.php"
                                                className="main-menu-submenu-item-a">Vraag een prijsopgave aan</a></p>
                                        </li>
                                        <li className="main-menu-submenu-item-li">
                                            <p className="main-menu-submenu-item-p"><a href="aanhuur-software.php"
                                                className="main-menu-submenu-item-a">Bekijk onze projecten</a></p>
                                        </li>
                                        <li className="main-menu-submenu-item-li">
                                            <p className="main-menu-submenu-item-p"><a href="aanhuur-software.php"
                                                className="main-menu-submenu-item-a">Bekijk ons team</a></p>
                                        </li>
                                    </ul>
                                </div>
                            </div>
                        </div>
                    </div>
                </li>
                <li className="main-menu-quick-butts-li">
                    <ul className="main-menu-quick-butts-listing-ul">
                        <li className="main-menu-quick-butt-li">
                            <Link to="/contact" className='main-menu-link'>
                                <button className="main-menu-quick-butt">MAAK EEN AFSPRAAK</button>
                            </Link>
                        </li>
                    </ul>
                </li>
                {isMobile && <li className="main-menu-mobile-menu-li">
                    <HamburgerMenu darkmode={darkmode} />
                </li>}
            </ul>
        </nav>
    );
};

export default NavBar;