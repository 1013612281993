// GoogleMapComponent.js
import React from 'react';
import { GoogleMap, useLoadScript, Marker } from '@react-google-maps/api';

interface GoogleMapComponentProps {
    lat: number;
    lng: number;
}

// Set up map container and options
const mapContainerStyle = {
  width: '100%',
  height: '100%',
};

const options = {
  disableDefaultUI: true, // Disables unnecessary UI controls
  zoomControl: false,      // Enables zoom control
};

const GoogleMapView: React.FC<GoogleMapComponentProps> = ({ lat, lng }) => {
  // Load the script with your API key
  const { isLoaded, loadError } = useLoadScript({
    googleMapsApiKey: 'AIzaSyAFrbIpz5AtlEuODkb9mLqmlBKt1jJtGSI', // Store API key in .env file for security
  });

  const center = {
    lat: lat,
    lng: lng,
  };

  if (loadError) return <div>Error loading maps</div>;
  if (!isLoaded) return <div>Loading Maps...</div>;

  return (
    <GoogleMap
      mapContainerStyle={mapContainerStyle}
      zoom={12}
      center={center}
      options={options}
    >
      <Marker position={center} />
    </GoogleMap>
  );
};

export default GoogleMapView;
