import React, { ReactNode } from 'react';
import NavBar from './NavBar/NavBar';
import Footer from './Footer/Footer';
import './Layout.css';

type LayoutProps = {
    children: ReactNode;
    darkmode?: boolean;
};

const Layout: React.FC<LayoutProps> = ({ children, darkmode }) => {
    return (
        <div>
            <NavBar darkmode={darkmode} />
            <div className="main-content-layout">
                {children}
            </div>
            <Footer />
        </div>
    );
};

export default Layout;