import axios from "axios";
import { ContactCreate } from "Models/Contact";

export const createContact = async (contact: ContactCreate): Promise<string> => {
    try {
        const response = await axios.post<string>('https://api.mogee.nl/contact', contact);
        return response.data;
    } catch (error) {
        console.error('Error creating contact:', error);
        throw error;
    }
};