import Layout from 'Components/Site/Layout/Layout';
import React from 'react';
import './AboutUsView.css';
import linkdinLogo from 'Assets/Img/socials/linkdin-icon.png';
import jurriaan from 'Assets/Img/Team/Jurriaan.jpg';
import matthijs from 'Assets/Img/Team/Matthijs.jpg';
import ruben from 'Assets/Img/Team/Ruben.jpg';
import constant from 'Assets/Img/Team/Connie.jpg';
import levy from 'Assets/Img/Team/Levy.jpg';
import marwen from 'Assets/Img/Team/Marwen.jpg';
import TypeWriter from 'Components/Site/HomeView/TypeWriter/TypeWriter';
import { useScrollToTop } from 'Utils/useScrollToTop';
import { Helmet } from 'react-helmet-async';
import usePageTracking from 'Utils/usePageTracking';

const AboutUs: React.FC = () => {
    useScrollToTop();
    usePageTracking();
    return (
        <Layout darkmode={true}>
            <Helmet>
                <title>MOGEE | Over ons</title>
                <meta name="description" content="MOGEE biedt betaalbare, op maat gemaakte software, ontwikkeld vanaf de werkvloer, om processen te automatiseren met transparantie en langdurig onderhoud." />
                <meta property="og:title" content="MOGEE | Over ons" />
                <meta property="og:description" content="MOGEE biedt betaalbare, op maat gemaakte software, ontwikkeld vanaf de werkvloer, om processen te automatiseren met transparantie en langdurig onderhoud." />
                <meta property="og:image" content="https://mogee.nl/static/media/mogee-logo-black.fecc3cb17bad839e2f14.png" />
                <meta property="og:site_name" content="MOGEE" />
                <meta property="og:type" content="website" />
                <meta property="og:url" content="https://mogee.nl/over-ons" />
                <meta name="twitter:title" content="MOGEE | Over ons" />
                <meta name="twitter:description" content="MOGEE biedt betaalbare, op maat gemaakte software, ontwikkeld vanaf de werkvloer, om processen te automatiseren met transparantie en langdurig onderhoud." />
                <meta name="twitter:image" content="https://mogee.nl/static/media/mogee-logo-black.fecc3cb17bad839e2f14.png" />
                <meta name="twitter:card" content="summary_large_image" />
            </Helmet>
            <div className="overons-hero-area">
                <h1 className="main-overons-title-h1">Wie zijn wij?</h1>
                <h1 className="main-overons-typwrite-title-h1">
                    <TypeWriter
                        textArray={[
                            "Wij maken software op maat",
                            "Wij hebben oog voor de eindgebruiker",
                            "Wij gebruiken de nieuwste technieken",
                            "Wij werken efficiënt",
                            "Wij zijn transparant",
                            "Wij zijn MOGEE"
                        ]}
                        period={2000}
                    />
                </h1>
                <p className="overons-txt-p">MOGEE biedt op maat gemaakte softwareontwikkeling en -beheer om jouw product of project te ondersteunen. Of je nu nieuwe software wilt bouwen of bestaande software wilt verbeteren, wij helpen je graag.<br></br><br></br>Ons team werkt nauw met je samen om een oplossing te creëren die aansluit bij de behoeften van je gebruikers. We bieden ook betrouwbaar beheer en onderhoud, zodat je software altijd up-to-date is en optimaal presteert. Met onze proactieve aanpak zorgen we ervoor dat je software klaar is voor de toekomst.</p>
            </div>
            <div className="overons-team-area">
                <div className="overons-team-item-div">
                    <img src={jurriaan} alt='Jurriaan Monincx' className="overons-team-person-img" />
                    <p className="overons-team-persoon-naam-txt-p">Jurriaan Monincx</p>
                    <p className="overons-team-persoon-functie-txt-p">Founder | Financieel Directeur</p>
                    <ul className="overons-team-persoon-socials-listing-ul">
                        <li className="overons-team-persoon-social-item-li">
                            <a href="https://www.linkedin.com/in/jurriaan-monincx-1b1b3b1b1/" rel='noopener' target="_blank"
                                className="overons-team-persoon-social-item-a">
                                <img src={linkdinLogo} alt='Linkdin' className="overons-team-persoon-social-item-img" />
                            </a>
                        </li>
                    </ul>
                </div>
                <div className="overons-team-item-div">
                    <img src={matthijs} alt='Matthijs Monincx' className="overons-team-person-img" />
                    <p className="overons-team-persoon-naam-txt-p">Matthijs Monincx</p>
                    <p className="overons-team-persoon-functie-txt-p">Founder | Commercieel Directeur</p>
                    <ul className="overons-team-persoon-socials-listing-ul">
                        <li className="overons-team-persoon-social-item-li">
                            <a href="https://www.linkedin.com/in/jurriaan-monincx-1b1b3b1b1/" rel='noopener' target="_blank"
                                className="overons-team-persoon-social-item-a">
                                <img src={linkdinLogo} alt='Linkdin' className="overons-team-persoon-social-item-img" />
                            </a>
                        </li>
                    </ul>
                </div>
                <div className="overons-team-item-div">
                    <img src={ruben} alt='Ruben Sánchéz' className="overons-team-person-img" />
                    <p className="overons-team-persoon-naam-txt-p">Rubén Sánchez</p>
                    <p className="overons-team-persoon-functie-txt-p">Software Engineer</p>
                    <ul className="overons-team-persoon-socials-listing-ul">
                        <li className="overons-team-persoon-social-item-li">
                            <a href="https://www.linkedin.com/in/jurriaan-monincx-1b1b3b1b1/" rel='noopener' target="_blank"
                                className="overons-team-persoon-social-item-a">
                                <img src={linkdinLogo} alt='Linkdin' className="overons-team-persoon-social-item-img" />
                            </a>
                        </li>
                    </ul>
                </div>
                <div className="overons-team-item-div">
                    <img src={levy} alt='Levy van der Linde' className="overons-team-person-img" />
                    <p className="overons-team-persoon-naam-txt-p">Levy van der Linde</p>
                    <p className="overons-team-persoon-functie-txt-p">Tech Sales Engineer</p>
                    <ul className="overons-team-persoon-socials-listing-ul">
                        <li className="overons-team-persoon-social-item-li">
                            <a href="https://www.linkedin.com/in/jurriaan-monincx-1b1b3b1b1/" rel='noopener' target="_blank"
                                className="overons-team-persoon-social-item-a">
                                <img src={linkdinLogo} alt='Linkdin' className="overons-team-persoon-social-item-img" />
                            </a>
                        </li>
                    </ul>
                </div>
                <div className="overons-team-item-div">
                    <img src={marwen} alt='Marwen Tlili' className="overons-team-person-img" />
                    <p className="overons-team-persoon-naam-txt-p">Marwen Tlili</p>
                    <p className="overons-team-persoon-functie-txt-p">Software Engineer</p>
                    <ul className="overons-team-persoon-socials-listing-ul">
                        <li className="overons-team-persoon-social-item-li">
                            <a href="https://www.linkedin.com/in/jurriaan-monincx-1b1b3b1b1/" rel='noopener' target="_blank"
                                className="overons-team-persoon-social-item-a">
                                <img src={linkdinLogo} alt='Linkdin' className="overons-team-persoon-social-item-img" />
                            </a>
                        </li>
                    </ul>
                </div>
                <div className="overons-team-item-div">
                    <img src={constant} alt='Constant Brummer' className="overons-team-person-img" />
                    <p className="overons-team-persoon-naam-txt-p">Constant Brummer</p>
                    <p className="overons-team-persoon-functie-txt-p">Software Engineer</p>
                    <ul className="overons-team-persoon-socials-listing-ul">
                        <li className="overons-team-persoon-social-item-li">
                            <a href="https://www.linkedin.com/in/jurriaan-monincx-1b1b3b1b1/" rel='noopener' target="_blank"
                                className="overons-team-persoon-social-item-a">
                                <img src={linkdinLogo} alt='Linkdin' className="overons-team-persoon-social-item-img" />
                            </a>
                        </li>
                    </ul>
                </div>
            </div>
            <div className="overons-text-area">
                <div className="overons-text-bar-div">
                    <div className="overons-text-bar-title-area">
                        <h5 className="overons-text-bar-title-subtitle-h5">Kijk op de toekomst</h5>
                        <h1 className="overons-text-bar-title-h1">Visie</h1>
                    </div>
                    <div className="overons-text-bar-text-area">
                        <p className="overons-text-bar-text-txt-p">
                            Op maat ontwikkelde software, gemaakt vanaf de werkvloer voor degene die de software dagelijks
                            gebruikt tegen een betaalbare prijs.<br></br>

                            Bij MOGEE geloven we dat de software oplossingen vanaf de werkvloer ontwikkeld moeten worden
                            zodat degene die de software gebruiken er daadwerkelijk mee kunnen werken. Hierdoor kunnen er
                            ook sneller stappen worden gezet waardoor we de prijs laag kunnen houden voor onze
                            developers.<br></br>

                            Tevens werken wij met lange termijn onderhoudscontracten zodat de software altijd veilig blijft
                            werken en streven wij naar een lang samenwerkingsverband met onze gewaardeerde klanten.
                        </p>
                    </div>
                </div>
                <div className="overons-text-bar-div">
                    <div className="overons-text-bar-title-area">
                        <h5 className="overons-text-bar-title-subtitle-h5">Waar we voor gaan</h5>
                        <h1 className="overons-text-bar-title-h1">Missie</h1>
                    </div>
                    <div className="overons-text-bar-text-area">
                        <p className="overons-text-bar-text-txt-p">
                            Betaalbare software oplossingen maken die processen verduurzamen, automatiseren en
                            digitaliseren. IT is de toekomst en moet ook voor de MKB betaalbaar en beschikbaar zijn zodat
                            kleine software oplossingen grote verschillen kunnen maken in bedrijfsprocessen.<br></br>

                            Bij MOGEE staan we voor een transparant prijzen model zodat onze klanten niet voor verrassingen
                            komen te staan.
                        </p>
                    </div>
                </div>

                <div className="overons-text-bar-div">
                    <div className="overons-text-bar-title-area">
                        <h5 className="overons-text-bar-title-subtitle-h5">een groeispurt</h5>
                        <h1 className="overons-text-bar-title-h1">Ons verhaal</h1>
                    </div>
                    <div className="overons-text-bar-text-area">
                        <p className="overons-text-bar-text-txt-p">
                            In 2013 start Matthijs met een chauffeursdienst in het Gooi onder de naam “Personal Driver Services” (PDS). In eerste instantie bood hij zijn diensten aan een selecte groep ondernemers in het Gooi aan. Wat eigenlijk als een bescheiden start begon, verspreidde zich als een lopend vuurtje door mond-tot-mondreclame. Al snel was Personal Driver Services synoniem met kwaliteit, flexibiliteit en betrouwbaarheid in het Gooi.<br></br><br></br>

                            In 2015 kreeg Matthijs hulp van Jurriaan, die samen met hem PDS wilde laten groeien. Wat begon met chauffeurs bob ritten groeide langzaamaan steeds meer de vraag naar zakelijke chauffeursdiensten. Terwijl ze de wegen doorkruisten met talloze klanten, begon de vraag naar studentenuitzendwerk daardoor ook gestaag toe te nemen.<br></br> <br></br>

                            Het jaar 2018 bracht de geboorte van “Temporary Employment Service” (TES) met zich mee, als reactie op de groeiende behoefte aan flexibele studentenarbeid. Dit bleek een waardevolle toevoeging te zijn, met bijvoorbeeld studenten die als milieucontroleurs containers aan stand bouwers verkochten en netheid handhaafden tijdens beursopbouw- en afbouwfases.<br></br> <br></br>

                            De lancering van de website <a href="http://www.personaldriverservices.nl" target="_blank" rel="noopener noreferrer">   www.personaldriverservices.nl </a> in 2020 markeerde een nieuwe stap met een vooruitstrevende ritten planner. Ritten konden vanaf nu gemakkelijk en geautomatiseerd worden aangevraagd via de website. Door de vele verhalen van klanten op de achterbank begon de interesse in IT steeds meer te groeien. De coronapandemie was het perfecte moment om een businessplan te schrijven voor de volgende stap van het bedrijf. <br></br><br></br>

                            Vanaf 2021 pakten Matthijs en Jurriaan de draad weer op en zagen ze een kans om software-oplossingen te ontwikkelen voor de RAI in Amsterdam. Hieruit ontstond MOOS, een ordersysteem voor containerbestellingen dat nog steeds wordt gebruikt in de RAI. Dit initiatief wekte een passie op voor op maat gemaakte software-oplossingen die rechtstreeks vanaf de werkvloer worden ontwikkeld. MOOS is sinds 2021 doorontwikkeld op meerdere locaties in Nederland en is uniek door de API-koppeling met ERP MOMENTUS events software.<br></br><br></br>

                            Met deze nieuwe passie en visie in gedachten, richtten Matthijs en Jurriaan Monincx op 2 februari 2022 MOGEE op. Hun doel: de IT-markt opschudden door betaalbare softwareoplossingen te ontwikkelen vanaf de werkvloer met transparante prijzen. Ze bouwden voort op hun ervaringen en ontwikkelingen bij PDS en TES, en richten zich op het creëren en aanbieden van op maat gemaakte software-oplossingen.<br></br><br></br>

                            Sinds januari 2024 onderhoudt en ontwikkelt MOGEE alle features voor vastgoedbeheer platform LOCUS EU. In opdracht van LOCUS is er vanaf scretch een plan ontwikkeld om de app op een zo logisch mogelijke manier te ontwikkelen waarmee zoveel mogelijk vastgoedbedrijven hun portefeuille kunnen beheren. <br></br><br></br>

                            Door de vele ontwikkelingen bij MOGEE was er nu ruimte om PDS te verkopen. Na een succesvolle online veiling via LinkedIn is PDS in mei 2024 verkocht aan ‘De Chauffeurs’ die het klantenbestand hebben overgenomen. <br></br><br></br>

                            Vanaf 2024 start MOGEE met de lancering van een nieuw eigen product. De “10-things” app, dé briefing app voor de horeca, is speciaal ontworpen voor de horecasector en is sinds september 2024 live. In deze app kunnen horecaondernemers hun plan van de dag opstellen per restaurant en rapporten inzien wat er zich daar dagelijks heeft afgespeeld. Door 5 KPI’s te koppelen aan de ‘things’ helpt de 10-Things app restaurants door heel Nederland hun service en omzet te verbeteren. <br></br>
                        </p>
                    </div>
                </div>
            </div>
        </Layout>
    );
};

export default AboutUs;