import React, { useEffect, useState } from 'react';
import './PortfolioItem.css';
import { Link } from 'react-router-dom';

interface PortfolioItemProps {
    title: string;
    description: string;
    url: string;
    imgRight: boolean;
    src: any;
    classes?: string;
}

const PortfolioItem: React.FC<PortfolioItemProps> = ({ title, description, url, imgRight, src, classes }) => {
    const [isMobile, setIsMobile] = useState(window.innerWidth <= 992);

    useEffect(() => {
        const handleResize = () => {
            setIsMobile(window.innerWidth <= 992);
        };

        window.addEventListener('resize', handleResize);
        return () => {
            window.removeEventListener('resize', handleResize);
        };
    }, []);

    const shouldImgBeRight = isMobile ? false : imgRight;

    return (
        <li className="portfolio-item-li">
            {shouldImgBeRight ? (
                <>
                    <div className="portfolio-item-left-area">
                        <div className="portfolio-item-inner-div">
                            <h2 className="portfolio-item-title-h2">{title}</h2>
                            <p className="portfolio-item-description-p">{description}</p>
                            <Link to={url} className="portfolio-item-link">
                                <button className="portfolio-item-btn">
                                    Bekijk project
                                </button>
                            </Link>
                        </div>
                    </div>
                    <div className="portfolio-item-right-area">
                        <img src={src} alt="" className={`portfolio-item-img ${classes}`} />
                    </div>
                </>
            ) : (
                <>
                    <div className="portfolio-item-right-area">
                        <img src={src} alt="" className={`portfolio-item-img ${classes}`} />
                    </div>
                    <div className="portfolio-item-left-area">
                        <div className="portfolio-item-inner-div">
                            <h2 className="portfolio-item-title-h2">{title}</h2>
                            <p className="portfolio-item-description-p">{description}</p>
                            <Link to={url} className="portfolio-item-link">
                                <button className="portfolio-item-btn">
                                    Bekijk project
                                </button>
                            </Link>
                        </div>
                    </div>
                </>
            )}
        </li>
    );
};

export default PortfolioItem;