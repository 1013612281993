import React from 'react';
import type { SVGProps } from 'react';

// Website Icon (fa-globe)
export function WebsiteIcon(props: SVGProps<SVGSVGElement>) {
	return (
		<svg xmlns="http://www.w3.org/2000/svg" width="1em" height="1em" viewBox="0 0 24 24" {...props}>
			<path fill="currentColor" d="M20 2H4a2 2 0 0 0-2 2v16a2 2 0 0 0 2 2h16a2 2 0 0 0 2-2V4a2 2 0 0 0-2-2zm0 18H4V8h16v12zm0-14H4V4h16v2z"/>
			<circle cx="6" cy="5" r="1" fill="currentColor" />
			<circle cx="9" cy="5" r="1" fill="currentColor" />
			<circle cx="12" cy="5" r="1" fill="currentColor" />
		</svg>
	);
}