import React from 'react';
import './WorkflowSection.css';

interface WorkflowSectionProps {
    // Define the props for the WorkflowSection component here
}

const WorkflowSection: React.FC<WorkflowSectionProps> = (props) => {
    // Implement the component logic here

    return (
        // JSX code for the WorkflowSection component goes here
        <section className='workflow-section'>
            <h1 className="workflow-section-title-h1">Onze workflow</h1>
            <h2 className="workflow-section-title-h2">MOGEE geeft een prijsgarantie zodat je niet voor verrassingen komt te staan!</h2>
            {/* Add your content here */}
            <div className="workflow-list">
                <div className="workflow-item">
                    <div className="workflow-step-counter">
                        <span>01</span>
                    </div>
                    <div className="workflow-content">
                        <h3 className='workflow-title-h3'>Orienterend gesprek op locatie</h3>
                        <p className="workflow-price-txt-p">GRATIS</p>
                        <p className='workflow-txt-p'>Je plant een afspraak met een van onze IT-consultants. Samen bespreken we welke oplossing het beste past bij jullie idee of probleem.</p>            
                    </div>
                </div>
                <div className="workflow-item">
                    <div className="workflow-step-counter">
                        <span>02</span>
                    </div>
                    <div className="workflow-content">
                        <h3 className='workflow-title-h3'>Maatwerkvoorstel</h3>
                        <p className="workflow-price-txt-p">Startpakket vastbedrag voor analyse en voorstel €1.000 - €5.000</p>
                        <p className='workflow-txt-p'>Op basis van jouw behoeften en budget stellen wij een op maat gemaakt voorstel op, waarin we precies beschrijven wat er ontwikkeld moet worden. Net als bij een architect maken we conceptschetsen en een sprintplanning. Op deze manier zorgen we er voor dat wij een transparante prijs kunnen bieden, waardoor jullie niet voor verrassingen komen te staan!</p>
                    </div>
                </div>
                <div className="workflow-item">
                    <div className="workflow-step-counter">
                        <span>03</span>
                    </div>
                    <div className="workflow-content">
                        <h3 className='workflow-title-h3'>Ontwikkeling MVP</h3>
                        <p className="workflow-price-txt-p">MVP €10.000-€20.000</p>
                        <p className='workflow-txt-p'>In deze fase bepalen we, op basis van jullie wensen, het minimum viable product (MVP). We maken duidelijke afspraken en leggen deze vast, zodat beide partijen precies weten wat ze kunnen verwachten. Onze developers werken in sprints van twee weken, waardoor je tussentijds kunt bijsturen als dat nodig is. We bespreken samen de voortgang tijdens sprintmeetings en houden de gewerkte uren nauwkeurig bij in onze urenadministratie.</p>
                    </div>
                </div>
                <div className="workflow-item">
                    <div className="workflow-step-counter">
                        <span>04</span>
                    </div>
                    <div className="workflow-content">
                        <h3 className='workflow-title-h3'>Van doorontwikkeling tot oplevering</h3>
                        <p className="workflow-price-txt-p">Prijsopbouw per functionaliteit €1.000 - €10.000</p>
                        <p className='workflow-txt-p'>We testen de eerste versie samen met eindgebruikers op de werkvloer. Eventuele aanpassingen en bugfixes worden doorgevoerd om binnen het afgesproken budget een volledig functioneel en gebruiksklaar eindproduct te garanderen (de MVP). Daarna gaan we verder met de doorontwikkeling van het product. Per functionaliteit, zoals overeengekomen, zullen we het product verder door ontwikkelen. Zo kunnen we jouw product stap voor stap samen optimaliseren en houden we controle over het budget.</p>
                    </div>
                </div>
                <div className="workflow-item">
                    <div className="workflow-step-counter">
                        <span>05</span>
                    </div>
                    <div className="workflow-content">
                        <h3 className='workflow-title-h3'>Service Level Agreement (SLA)</h3>
                        <p className="workflow-price-txt-p">Vanaf €1.000 per maand</p>
                        <p className='workflow-txt-p'>Om te garanderen dat jouw product optimaal functioneert, veilig is en toekomstbestendig blijft, raden wij altijd een SLA aan. De hosting, bugfixes, en kleine aanpassingen zijn inbegrepen. Zo zorgen we voor een goed werkend product voor jou en/of de eindgebruiker!</p>
                    </div>
                </div>
            </div>
        </section>

    );
};

export default WorkflowSection;