import React, { useEffect } from 'react';
import Layout from '../../../Components/Site/Layout/Layout';
import './HomeView.css';
import TypeWriter from '../../../Components/Site/HomeView/TypeWriter/TypeWriter';
import CountUp from '../../../Components/Site/HomeView/CountUp/CountUp';
import PortfolioItem from '../../../Components/Site/HomeView/PortfolioSection/PortfolioItem/PortfolioItem';
// import mogeebackground from '../../../Assets/Img/mogee-achtergrond.jpg';
import video from 'Assets/Img/mogee-achtergrond-filmpje-HD_1.mp4';
import mockup10things from 'Assets/Img/Mockups/mockup-new-app-cut.png';
import mockupmoos from 'Assets/Img/Mockups/moos-mockup.png';
import mockupLocus from 'Assets/Img/Mockups/locus-mockup.png';
import mogeeTeam from 'Assets/Img/mogee-team-kleur.jpg';
import { ApplicationIcon } from '../../../Assets/Icons/ApplicationIcon';
import { DesignIcon } from '../../../Assets/Icons/DesignIcon';
import { CustomIcon } from '../../../Assets/Icons/CustomIcon';
import { HostingIcon } from '../../../Assets/Icons/HostingIcon';
import { WorkFlowStep } from '../../../Models/WorkFlowStep';
import { WebsiteIcon } from 'Assets/Icons/WebsiteIcon';
import { IntegrationIcon } from 'Assets/Icons/IntegrationIcon';
import WorkflowSteps from 'Components/Site/HomeView/WorkflowSteps/WorkflowSteps';
import exactlogo from 'Assets/Img/partners/exact-logo.svg';
import locuslogo from 'Assets/Img/partners/locus-logo.svg';
import pdslogo from 'Assets/Img/partners/pds-logo.png';
import picniclogo from 'Assets/Img/partners/picnic-logo.svg';
import railogo from 'Assets/Img/partners/rai-logo.png';
import vanlanschotlogo from 'Assets/Img/partners/vanlanschot-logo.svg';
import { Helmet } from 'react-helmet-async';
import { Link, useLocation, useNavigate } from 'react-router-dom';
import { Swiper, SwiperSlide } from 'swiper/react';
import { Autoplay, Pagination } from 'swiper/modules';
import 'swiper/css';
import 'swiper/css/pagination';
import 'swiper/swiper-bundle.css';
import WorkflowSection from '../../../Components/Site/HomeView/WorkflowSection/WorkflowSection';
import usePageTracking from 'Utils/usePageTracking';


function ScrollToHashElement() {
    const { hash } = useLocation();
    const navigate = useNavigate();

    useEffect(() => {
        if (hash) {
            const element = document.getElementById(hash.replace('#', ''));
            if (element) {
                element.scrollIntoView();
                // Remove the hash from the URL
                navigate(window.location.pathname + window.location.search, { replace: true });
            }
        }
    }, [hash, navigate]);

    return null;
}

const HomeView: React.FC = () => {
    usePageTracking();
    const steps: WorkFlowStep[] = [
        {
            title: "Concept",
            description: "We analyseren uw wensen en doelstellingen en ontwikkelen een gedetailleerd projectplan. Door gebruikersbehoeften te onderzoeken en het project in sprints te plannen, kunnen we flexibel bijsturen tijdens het proces.",
            image: mockupLocus
        },
        {
            title: "Naar de tekentafel",
            description: "We maken schetsen om het concept visueel te maken en te zorgen dat we op één lijn zitten. Het ontwerp wordt verder verfijnd voor optimale gebruiksvriendelijkheid. ",
            image: mockup10things
        },
        {
            title: "Aan de slag ",
            description: "Onze developers realiseren uw idee in sprints, waardoor je tussentijds kunt bijsturen. We reviewen de voortgang samen tijdens meetings en documenteren alles voor transparantie. ",
            image: mockup10things
        },
        {
            title: "Testen",
            description: "We testen de eerste versie met de eindgebruiker in gedachten. Aanpassingen en bugs worden aangepakt om een volledig functioneel en gebruiksklaar eindproduct te garanderen. ",
            image: mockup10things
        },
        {
            title: "Eindproduct",
            description: "We brengen het product naar “productie” en bieden doorlopend onderhoud en service aan. Zo blijft het goed functioneren en kunnen we samen continue verbeteringen doorvoeren. ",
            image: mockup10things
        }
    ];
    return (
        <Layout>
            <ScrollToHashElement />
            <Helmet>
                <title>MOGEE | Software vanaf de werkvloer</title>
                <meta name="description" content="MOGEE biedt betaalbare, op maat gemaakte software, ontwikkeld vanaf de werkvloer, om processen te automatiseren met transparantie en langdurig onderhoud." />
            </Helmet>
            <div className="index-hero-area-div">
                <video autoPlay muted playsInline controls={false} className="background-video">
                    <source src={video} type="video/mp4" />
                </video>
                <div className="index-hero-inner-div">
                    <div className="index-hero-text-div">
                        <h1 className="index-hero-title-h1">
                            <TypeWriter
                                textArray={[
                                    "Wij maken software op maat",
                                    "Wij hebben oog voor de eindgebruiker",
                                    "Wij gebruiken de nieuwste technieken",
                                    "Wij werken efficiënt",
                                    "Wij zijn transparant",
                                    "Wij zijn MOGEE"
                                ]}
                                period={2000}
                            />
                        </h1>
                        <p className="index-hero-small-txt-p">Ontwikkeld vanaf de werkvloer met oog voor de eindgebruiker</p>
                        {/* <div className="index-hero-quick-info-area-div">
                            <div className="index-hero-quick-info-item-div" data-target-value="10">
                                <CountUp targetValue={20} />
                                <p className="index-hero-quick-info-var-txt-p">Jaar ervaring</p>
                            </div>

                            <div className="index-hero-quick-info-item-div" data-target-value="6">
                                <CountUp targetValue={1000} />
                                <p className="index-hero-quick-info-var-txt-p">Tevreden gebruikers</p>
                            </div>

                            <div className="index-hero-quick-info-item-div" data-target-value="6">
                                <CountUp targetValue={10000} />
                                <p className="index-hero-quick-info-var-txt-p">Transacties verwerkt</p>
                            </div>
                        </div> */}
                    </div>
                </div>
            </div>

            {/* <section className="review-section">
                <div className="home-reviews-container">
                    <h1 className="home-partners-title-h1" >Wat zeggen onze klanten: </h1>
                
                    <div className="review-inner-div">
                    <Swiper
                            spaceBetween={50}
                            slidesPerView={1}
                            autoplay={{ delay: 5000 }}
                            loop={true}
                            modules={[Autoplay]}
                            onSlideChange={() => console.log('slide change')}
                            onSwiper={(swiper: any) => console.log(swiper)}
                    >

                            <SwiperSlide>
                                <div className="review-item-div"> 
                                    <div className="review-text-div">
                                        <p>“De totaal vernieuwde oplossing van 10-Things maakt het briefen en evalueren van een shift kinderlijk eenvoudig én zorgt voor de documentatie van de dagelijkse processen!”</p>
                                    </div>
                                </div>
                            </SwiperSlide>
                            <SwiperSlide>
                                <div className="review-item-div"> 
                                    <div className="review-text-div">
                                        <p>“Wij zijn zeer tevreden met de briefings die wij sinds april houden met de 10-Things app! Onze tablet hangt zichtbaar in de keuken zodat het hele team gedurende de dag weet wat het plan van de dag is.”</p>
                                    </div>
                                </div>
                            </SwiperSlide>
                            
                        </Swiper>

                    </div>

                    </div>
            </section> */}

            <section className="index-partners-section">
                {/* <h1 className="index-title-h1">Onze partners</h1> */}
                <div className="index-partner-inner-div">
                    <div className="index-partner-item-div">
                        <img src={exactlogo} alt='Exact' className="index-partner-item-img" />
                    </div>
                    <div className="index-partner-item-div">
                        <img src={locuslogo} alt='Locus' className="index-partner-item-img" />
                    </div>
                    <div className="index-partner-item-div">
                        <img src={pdslogo} alt='PDS' className="index-partner-item-img" />
                    </div>
                    <div className="index-partner-item-div">
                        <img src={picniclogo} alt='Picnic' className="index-partner-item-img" />
                    </div>
                    <div className="index-partner-item-div">
                        <img src={railogo} alt='RAI' className="index-partner-item-img" />
                    </div>
                    <div className="index-partner-item-div">
                        <img src={vanlanschotlogo} alt='van Lanschot' className="index-partner-item-img" />
                    </div>
                </div>
            </section>

            <section className="index-portfolio-section" id='portfolio'>
                <h1 className="index-title-h1">Portfolio</h1>
                <ul className="index-portfolio-listing-ul">
                    <PortfolioItem title={'10-Things'} description={'Dé briefing app voor de horeca. 10-Things is een van MOGEE haar eigen producten. Voor dit product hebben wij ontwikkeld: een website, web app en mobile app in de Appstore.'} url={'/project/10-things'} imgRight={true} src={mockup10things} />
                    <PortfolioItem title={'MOOS'} description={'Dé onsite order app voor evenementenlocaties. MOOS maakt het mogelijk om bestellingen te plaatsen en af te leveren op evenementenlocaties zoals de RAI Amsterdam. MOOS is een van MOGEE haar eigen producten en bestaat uit een web app met geïntegreerde API-verbinding.'} url={'/project/moos'} imgRight={false} src={mockupmoos} />
                    <PortfolioItem title={'LOCUS'} description={'LOCUS is een Property Management Systeem. Met deze software wordt vastgoedbeheer eenvoudig. MOGEE is verantwoordelijk voor de (door)ontwikkeling, service en onderhoud van deze software.'} url={'/project/locus'} imgRight={true} src={mockupLocus} classes={'locus-mockup-img'} />
                </ul>
            </section>

            <WorkflowSection />

            <section className="index-services-section" id='services'>
                <h1 className="index-title-h1">Diensten</h1>
                <div className="index-services-inner-div">
                    <div className="index-services-item-div">
                        <ApplicationIcon className="index-services-item-icon" />
                        <h2 className="index-services-item-title-h2">Software ontwikkeling</h2>
                        <p className="index-services-item-txt-p">Afgestemd op de specifieke behoeften van uw bedrijf, wordt software op maat ontwikkeld met een flexibele en agile werkwijze in sprints van 2 weken</p>
                    </div>
                    <div className="index-services-item-div">
                        <DesignIcon className="index-services-item-icon" />
                        <h2 className="index-services-item-title-h2">Product Design</h2>
                        <p className="index-services-item-txt-p">Of je nu een uniek design voor ogen hebt of hulp nodig hebt bij het vormgeven ervan, samen realiseren we uw ideeën!</p>
                    </div>
                    <div className="index-services-item-div">
                        <CustomIcon className="index-services-item-icon" />
                        <h2 className="index-services-item-title-h2"> Oplossingen op maat</h2>
                        <p className="index-services-item-txt-p">Bij MOGEE geloven we dat software pas 100% werkt wanneer het volledig op maat is gemaakt voor uw specifieke behoeften, ontwikkeld vanuit de praktijk en met oog voor de eindgebruiker</p>
                    </div>
                    <div className="index-services-item-div">
                        <HostingIcon className="index-services-item-icon" />
                        <h2 className="index-services-item-title-h2">Hosting</h2>
                        <p className="index-services-item-txt-p">Wij bieden professionele hosting voor uw software via AWS, Azure, of onze eigen servers, waarmee we zorgen voor optimale beveiliging en constante beschikbaarheid van de software. Deze service wordt met de u afgestemd in een Service Level Agreement (SLA)</p>
                    </div>

                    <div className="index-services-item-div">
                        <IntegrationIcon className="index-services-item-icon" />
                        <h2 className="index-services-item-title-h2">Integraties</h2>
                        <p className="index-services-item-txt-p">Integreer je bedrijfsprocessen met MOGEE in zowel nieuwe als bestaande software. Ons team heeft uitgebreide ervaring met integraties, waaronder Exact, Mollie en API-koppelingen</p>
                    </div>

                    <div className="index-services-item-div">
                        <WebsiteIcon className="index-services-item-icon" />
                        <h2 className="index-services-item-title-h2">Websites</h2>
                        <p className="index-services-item-txt-p">Bouw samen met ons een volledig op maat gemaakte website, inclusief ontwerp, integraties en koppelingen</p>
                    </div>
                </div>
            </section>



            <section className="index-cta-section">
                <h1 className="index-title-h1 cta">Hoe kunnen we u helpen?</h1>
                <p className="index-cta-txt-p">Laten we een digitaal product ontwikkelen dat toekomstbestendig, schaalbaar, veilig en gemakkelijk te onderhouden is!</p>
                <div className="index-cta-hoewewerken-area">
                    <div className="index-cta-hoewewerken-item-cards">
                        <div className="index-cta-hoewewerken-top-area">
                            <span className="index-cta-hoewewerken-step-span">
                                Stap 1
                            </span>
                        </div>
                        <div className="index-cta-hoewewerken-content-area">
                            <h4 className="index-cta-hoewewerken-title-h4">Neem contact op of maak een afspraak</h4>
                            <p className="index-cta-hoewewerken-txt-p">Stuur ons een email of bel ons, wij staan graag voor je klaar!</p>
                        </div>
                    </div>

                    <div className="index-cta-hoewewerken-item-cards">
                        <div className="index-cta-hoewewerken-top-area">
                            <span className="index-cta-hoewewerken-step-span">
                                Stap 2
                            </span>
                        </div>
                        <div className="index-cta-hoewewerken-content-area">
                            <h4 className="index-cta-hoewewerken-title-h4">Ontmoeting op locatie </h4>
                            <p className="index-cta-hoewewerken-txt-p">We maken een afspraak op locatie om uw wensen in detail te bespreken en te bepalen of wij de juiste oplossing kunnen bieden. </p>
                        </div>
                    </div>

                    <div className="index-cta-hoewewerken-item-cards">
                        <div className="index-cta-hoewewerken-top-area">
                            <span className="index-cta-hoewewerken-step-span">
                                Stap 3
                            </span>
                        </div>
                        <div className="index-cta-hoewewerken-content-area">
                            <h4 className="index-cta-hoewewerken-title-h4">Ontvang een voorstel</h4>
                            <p className="index-cta-hoewewerken-txt-p">Op basis van uw behoeften stellen wij een maatwerkvoorstel op. We denken met je mee om een oplossing te creëren die perfect bij je wensen past. </p>
                        </div>
                    </div>

                    <div className="index-cta-hoewewerken-item-cards">
                        <div className="index-cta-hoewewerken-top-area">
                            <span className="index-cta-hoewewerken-step-span">
                                Stap 4
                            </span>
                        </div>
                        <div className="index-cta-hoewewerken-content-area">
                            <h4 className="index-cta-hoewewerken-title-h4">Afspraken en uitvoering</h4>
                            <p className="index-cta-hoewewerken-txt-p">We maken duidelijke afspraken en leggen deze vast, zodat beide partijen weten wat er verwacht wordt. Na ondertekening gaan we direct aan de slag om uw idee te verwezenlijken. </p>
                        </div>
                    </div>
                </div>
                <div className="index-cta-buttons-area">
                    <Link to="/contact">
                        <button className='index-cta-button improve'>
                            Aan de slag
                        </button>
                    </Link>
                </div>
            </section>
            <section className="index-aboutus-section">
                <div className="index-overons-img-area">
                    <img src={mogeeTeam}
                        className="index-overons-img" alt='MOGEE Team' />
                </div>
                <div className="index-overons-txt-area">
                    <div className="index-overons-inner-txt-div">
                        <h1 className="index-title-h1 overons">Over ons</h1>
                        <p className="index-overons-txt-p">
                            Bij MOGEE ontwikkelen we op maat gemaakte software die gericht is op de eindgebruiker. We geloven dat software pas echt effectief is wanneer deze naadloos aansluit bij de behoeften van degenen die ermee werken.<br></br><br></br> Onze development teams werken efficiënt door gebruik te maken van de nieuwste technieken, waaronder slimme AI-toepassingen. Onze missie is om softwareoplossingen te bieden die bedrijfsprocessen automatiseren en digitaliseren tegen een eerlijke prijs. We streven altijd naar langdurige samenwerkingen met onze klanten, omdat we geloven dat software continu verbeterd, verfijnd en geoptimaliseerd kan worden. We staan klaar om je daarbij te ondersteunen!
                        </p>
                        <Link to="/over-ons">
                            <button className="index-overons-btn">Lees meer</button>
                        </Link>
                    </div>
                </div>
            </section>

        </Layout>
    );
};

export default HomeView;