import React, { useEffect, useState } from 'react';
import { motion } from 'framer-motion';
import './HamburgerMenu.css';
import { Link, useLocation } from 'react-router-dom';

interface HamburgerMenuProps {
    darkmode?: boolean;
}

const HamburgerMenu: React.FC<HamburgerMenuProps> = ({ darkmode }) => {
    const [isOpen, setIsOpen] = useState(false);
    const [transitionEnd, setTransitionEnd] = useState(0.4);

    const toggleMenu = () => {
        setTransitionEnd(0.4);
        setIsOpen(!isOpen);
    };

    const toggleMenuOnLocationChange = () => {
        setTransitionEnd(0);
        setIsOpen(false);
    }


    return (
        <div className="hamburger-menu-container">
            <motion.div
                className={`hamburger-icon ${darkmode ? 'dark' : ''}`}
                onClick={toggleMenu}
                initial={false}
                animate={isOpen ? 'open' : 'closed'}
                variants={{
                    open: { rotate: 90 },
                    closed: { rotate: 0 },
                }}
            >
                <motion.span
                    className="line"
                    variants={{
                        open: { rotate: 45, y: 6 },
                        closed: { rotate: 0, y: 0 },
                    }}
                />
                <motion.span
                    className="line"
                    variants={{
                        open: { opacity: 0 },
                        closed: { opacity: 1 },
                    }}
                    transition={{ duration: 0.1 }}
                />
                <motion.span
                    className="line"
                    variants={{
                        open: { rotate: -45, y: -14 },
                        closed: { rotate: 0, y: 0 },
                    }}
                />
            </motion.div>
            <motion.div
                className="menu"
                initial={false}
                animate={isOpen ? 'open' : 'closed'}
                variants={{
                    open: { opacity: 1, y: 0, display: 'block' },
                    closed: { opacity: 1, y: '-100vh', transitionEnd: { display: 'none' } },
                }}
                transition={{ duration: transitionEnd }}
            >
                <ul>
                    <li>
                        <Link to="/#portfolio" onClick={toggleMenuOnLocationChange}>
                            <button className="hamburger-menu-btn" type='button'>
                                Portfolio
                            </button>
                        </Link>
                    </li>
                    <li>
                        <Link to="/#services" onClick={toggleMenuOnLocationChange}>
                            <button className="hamburger-menu-btn" type='button'>
                                Services
                            </button>
                        </Link>
                    </li>
                    <li>
                        <Link to="/over-ons">
                            <button className="hamburger-menu-btn" type='button'>
                                Over ons
                            </button>
                        </Link>
                    </li>
                    <li>
                        <Link to="/contact">
                            <button className="hamburger-menu-btn" type='button'>
                                Contact
                            </button>
                        </Link>
                    </li>
                </ul>
            </motion.div>
        </div>
    );
};

export default HamburgerMenu;